<template>
  <div class="flex fixed bottom-0 left-0 right-0 items-end p-1 bg-base-300 shadow-lg border-t-2 border-accent-content/10">
    <form @submit.prevent="onSubmit" v-if="messageMode == 'text'">
      <textarea auto autofocus class="textarea textarea-bordered grow" name="message" placeholder="Cosa vuoi chiedermi?"
        required v-model="form.message"></textarea>
      <button class="btn btn-success btn-circle text-base-100" v-if="form.message" type="button">
        <i class="ph ph-paper-plane-right text-lg"></i>
      </button>
      <button class="btn btn-success btn-circle text-base-100" v-if="!form.message" type="button"
        @click="messageMode = 'audio'">
        <i class="ph ph-microphone text-lg"></i>
      </button>
    </form>

    <div v-if="messageMode == 'audio'">
      Record audio...
      <button type="button" @click="messageMode = 'text'">Cancel</button>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { ref } from "vue";

const messageMode = ref('text');
const form = ref({
  message: "",
});

const onSubmit = () => {
  axios.post(AppConfig.url + "/prompt", {
    message: form.value.message,
  });
};

const initAudioRecorder = () => {

}
</script>

<style lang="scss" scoped></style>
